<template>
  <div data-component-name="PhoneInput">
    <label>
      {{ $t('Phone') + (required ? '*' : '') }}
    </label>

    <VueTelInput
      :id="`phoneInput-${id}`"
      v-model="internalValue"
      :auto-default-country="false"
      :default-country="countryISO"
      :input-options="{
        name: 'phone',
        showDialCode: false,
        placeholder: $t('Enter_a_phone_number'),
      }"
      :dropdown-options="{
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showSearchBox: true,
        showFlags: true,
        searchBoxPlaceholder: $t('Search_by_country_name'),
      }"
      ref="vueTelInputRef"
      mode="national"
      @on-input="inputHandler"
    >
      <template #arrow-icon>
        <button type="button">
          <SvgArrowDownFill />
        </button>
      </template>
    </VueTelInput>

    <span v-if="error" class="error">
      {{ error }}
    </span>
  </div>
</template>

<script setup lang="ts">
// https://iamstevendao.com/vue-tel-input/

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import get from 'lodash.get';

type VueTelInputRef = {
  choose(value: string): void
  phoneObject: {
    country?: {
      areaCodes: null
      dialCode: string
      iso2: string
      name: string
      priority: number
    },
    number: string
    valid?: boolean
    formatted: string
  },
};

const props = withDefaults(
  defineProps<{
    modelValue: string
    required?: boolean
    error?: string
    countryISO?: string
  }>(),
  {
    required: false,
    countryISO: 'US',
  },
);
const emit = defineEmits<{ 'update:modelValue': [value: string] }>();

const id = useId();
const internalValue = ref(props.modelValue || '');

const vueTelInputRef = ref<null | VueTelInputRef>(null);

const inputHandler = (value: string) => {
  if (value === props.modelValue) return;

  emit(
    'update:modelValue',
    get(vueTelInputRef.value, 'phoneObject.number', value),
  );
};

watch(toRef(props, 'countryISO'), value => {
  if (!vueTelInputRef.value) return;
  vueTelInputRef.value.choose(value);
});

defineExpose({
  isValid: () => vueTelInputRef.value?.phoneObject.valid,
});
</script>

<style scoped lang="scss">
@import "$/mixins/common";
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="PhoneInput"] {
  position: relative;
  width: 100%;

  label {
    @include caption;
    padding: 0 0.25rem;
    background-color: token('surf-cont-primary');

    position: absolute;
    left: 1rem;
    transform: translateY(-50%);
    z-index: 1;
  }

  &:deep(.vue-tel-input) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    position: relative;

    background-color: token('surf-cont-secondary');

    transition: all 0.15s ease;

    .vti__dropdown {
      position: static;
      padding: unset;
      background-color: unset;

      &.open {
        .vti__selection {
          button {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .vti__selection {
        @include flex-center-center;
        gap: 0.25rem;
        padding: 1rem;

        transition: background-color 0.15s ease;

        .vti__flag {}

        .vti__country-code {
          @include body-3;
          color: token('text-primary');
          user-select: none;
        }

        button {
          transform: translateY(0.15rem);

          svg {
            transition: transform 0.15s ease;
          }
        }

        &:active {
          background-color: rgba(token('accent-selected'), 8%);
        }
      }

      .vti__dropdown-list {
        border: unset;

        position: absolute;
        left: 0;
        right: 0;
        width: 100%;

        box-shadow: map-get($shadows, 'Elevation 1');

        &.above {
          bottom: calc(100% + 0.5rem);
        }

        &.below {
          top: calc(100% + 0.25rem);
        }

        input.vti__search_box {
          border-radius: unset;
          width: 100%;

          padding: 0.75rem 1rem;
          margin: 0;

          transition: box-shadow 0.15s ease;

          @include body-3;

          color: token('text-primary');
          caret-color: token('outline-action');

          &:focus {
            box-shadow: inset 0 0 0 1px token('outline-action');
          }

          &::placeholder {
            color: token('text-secondary');
          }
        }

        .vti__dropdown-item {
          padding: 0.75rem 1rem;
          transition: background-color 0.15s ease;

          @include flex-center-start;
          gap: 0.25rem;

          &.highlighted {
            background-color: unset;
          }

          .vti__flag {}

          strong {
            @include body-3;
          }

          span:not(.vti__flag) {
            color: token('text-secondary');
            @include body-3;
          }

          &:hover {
            background-color: rgba(token('accent-hover'), 16%);
          }

          &:active {
            background-color: rgba(token('accent-hover'), 24%);
          }
        }
      }
    }

    > input.vti__input {
      border-radius: unset;
      background-color: unset;

      padding: 1rem 1rem 1rem 0.5rem;

      caret-color: token('outline-action');

      @include body-3;

      &::placeholder {
        color: token('text-secondary');
      }
    }

    &:hover {
      background-color: rgba(token('accent-selected'), 16%);
    }

    &:has(> input.vti__input:focus) {
      box-shadow: inset 0 0 0 1px token('outline-action');
    }
  }

  span.error {
    @include caption;
    color: token('error');
    margin: 0.25rem 0 0 1rem;
    display: block;
  }
}
</style>
